import React, { useState } from 'react';
import { FieldTextInput, PrimaryButton, SecondaryButton } from '../../../../../components';
import css from './CoordinateInputFields.module.css';

const CoordinateInputFields = ({ formApi, values, handleClear, handleConfirmLocation }) => {
  const [activeTab, setActiveTab] = useState(values?.locationType || 'DD');

  return (
    <div className={css.locationBox}>
      {/* Tabs for selecting coordinate format */}
      <div className={css.tabs}>
        <button
          type="button"
          className={activeTab === 'DD' ? css.activeTab : css.tab}
          onClick={() => setActiveTab('DD')}
        >
          Decimal Degrees <br />
          (DD)
        </button>
        <button
          type="button"
          className={activeTab === 'DDM' ? css.activeTab : css.tab}
          onClick={() => setActiveTab('DDM')}
        >
          Degrees Decimal Minutes <br /> (DDM)
        </button>
        <button
          type="button"
          className={activeTab === 'DMS' ? css.activeTab : css.tab}
          onClick={() => setActiveTab('DMS')}
        >
          Degrees Minutes Seconds <br /> (DMS)
        </button>
      </div>

      {/* Conditionally render fields based on the active tab */}
      <div className={css.formFields}>
        {/* Decimal Degrees (DD) Format */}
        {activeTab === 'DD' && (
          <>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="latitudeDD"
                name="latitudeDD"
                className={css.textField}
                type="number"
                label="Latitude"
                placeholder="e.g., 11.033333"
                step="0.000001"
              />
              <span className={css.unit}>°</span>
            </div>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="longitudeDD"
                name="longitudeDD"
                className={css.textField}
                type="number"
                label="Longitude"
                placeholder="e.g., 10.2"
                step="0.000001"
              />
              <span className={css.unit}>°</span>
            </div>
          </>
        )}

        {/* Degrees Decimal Minutes (DDM) Format */}
        {activeTab === 'DDM' && (
          <>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="latitudeDegrees"
                name="latitudeDegrees"
                className={css.textField}
                type="number"
                label="Latitude Degrees"
                placeholder="11"
              />
              <span className={css.unit}>°</span>
              <FieldTextInput
                id="latitudeMinutes"
                name="latitudeMinutes"
                className={css.textField}
                type="number"
                label="Minutes"
                placeholder="2"
                step="0.0001"
              />
              <FieldTextInput
                id="latitudeDirection"
                name="latitudeDirection"
                className={css.directionField}
                component="select"
              >
                <option value="N">N</option>
                <option value="S">S</option>
              </FieldTextInput>
            </div>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="longitudeDegrees"
                name="longitudeDegrees"
                className={css.textField}
                type="number"
                label="Longitude Degrees"
                placeholder="10"
              />
              <span className={css.unit}>°</span>
              <FieldTextInput
                id="longitudeMinutes"
                name="longitudeMinutes"
                className={css.textField}
                type="number"
                label="Minutes"
                placeholder="12"
                step="0.0001"
              />
              <FieldTextInput
                id="longitudeDirection"
                name="longitudeDirection"
                className={css.directionField}
                component="select"
              >
                <option value="E">E</option>
                <option value="W">W</option>
              </FieldTextInput>
            </div>
          </>
        )}

        {/* Degrees Minutes Seconds (DMS) Format */}
        {activeTab === 'DMS' && (
          <>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="latitudeDegreesDMS"
                name="latitudeDegreesDMS"
                className={css.textField}
                type="number"
                label="Latitude Degrees"
                placeholder="11"
              />
              <span className={css.unit}>°</span>
              <FieldTextInput
                id="latitudeMinutesDMS"
                name="latitudeMinutesDMS"
                className={css.textField}
                type="number"
                label="Minutes"
                placeholder="1"
              />
              <span className={css.unit}>'</span>
              <FieldTextInput
                id="latitudeSecondsDMS"
                name="latitudeSecondsDMS"
                className={css.textField}
                type="number"
                label="Seconds"
                placeholder="60"
                step="0.0001"
              />
              <span className={css.unit}>"</span>
              <FieldTextInput
                id="latitudeDirectionDMS"
                name="latitudeDirectionDMS"
                className={css.directionField}
                component="select"
              >
                <option value="N">N</option>
                <option value="S">S</option>
              </FieldTextInput>
            </div>
            <div className={css.coordinateField}>
              <FieldTextInput
                id="longitudeDegreesDMS"
                name="longitudeDegreesDMS"
                className={css.textField}
                type="number"
                label="Longitude Degrees"
                placeholder="10"
              />
              <span className={css.unit}>°</span>
              <FieldTextInput
                id="longitudeMinutesDMS"
                name="longitudeMinutesDMS"
                className={css.textField}
                type="number"
                label="Minutes"
                placeholder="12"
              />
              <span className={css.unit}>'</span>
              <FieldTextInput
                id="longitudeSecondsDMS"
                name="longitudeSecondsDMS"
                className={css.textField}
                type="number"
                label="Seconds"
                placeholder="0"
                step="0.0001"
              />
              <span className={css.unit}>"</span>
              <FieldTextInput
                id="longitudeDirectionDMS"
                name="longitudeDirectionDMS"
                className={css.directionField}
                component="select"
              >
                <option value="E">E</option>
                <option value="W">W</option>
              </FieldTextInput>
            </div>
          </>
        )}
      </div>

      {/* Clear and Confirm Location Buttons */}
      <div className={css.buttonContainer}>
        <SecondaryButton
          type="button"
          className={css.clearButton}
          onClick={() => handleClear(activeTab)}
        >
          Reset Values
        </SecondaryButton>
        <PrimaryButton
          type="button"
          className={css.confirmButton}
          onClick={() => handleConfirmLocation(activeTab)}
        >
          Confirm Location
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CoordinateInputFields;
