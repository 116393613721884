import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { DashboadPageNavOption, StatusCode } from '../../util/enums';
import {
  fetchUsersDetails,
  fetchListingDetails,
  fetchMessagesDetails,
  fetchTransactionsDetails,
  transactionTransition,
  fetchHostsStripeDetails,
  fetchHostListingsSummary,
} from '../../util/api';

const RESULTS_PER_PAGE = 100;
const PAGE = 1;

// ================ Action types ================ //

export const USER_DETAILS_REQUEST = 'app/DashboardPage/USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'app/DashboardPage/USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'app/DashboardPage/USER_DETAILS_ERROR';

export const RECENT_LISTINGS_REQUEST = 'app/DashboardPage/RECENT_LISTINGS_REQUEST';
export const RECENT_LISTINGS_SUCCESS = 'app/DashboardPage/RECENT_LISTINGS_SUCCESS';
export const RECENT_LISTINGS_ERROR = 'app/DashboardPage/RECENT_LISTINGS_ERROR';

export const RECENT_MESSAGES_REQUEST = 'app/DashboardPage/RECENT_MESSAGES_REQUEST';
export const RECENT_MESSAGES_SUCCESS = 'app/DashboardPage/RECENT_MESSAGES_SUCCESS';
export const RECENT_MESSAGES_ERROR = 'app/DashboardPage/RECENT_MESSAGES_ERROR';

export const RECENT_TRANSACTIONS_REQUEST = 'app/DashboardPage/RECENT_TRANSACTIONS_REQUEST';
export const RECENT_TRANSACTIONS_SUCCESS = 'app/DashboardPage/RECENT_TRANSACTIONS_SUCCESS';
export const RECENT_TRANSACTIONS_ERROR = 'app/DashboardPage/RECENT_TRANSACTIONS_ERROR';

export const TRANSACTION_TRANSITION_REQUEST = 'app/DashboardPage/TRANSACTION_TRANSITION_REQUEST';
export const TRANSACTION_TRANSITION_SUCCESS = 'app/DashboardPage/TRANSACTION_TRANSITION_SUCCESS';
export const TRANSACTION_TRANSITION_ERROR = 'app/DashboardPage/TRANSACTION_TRANSITION_ERROR';

export const HOST_STRIPE_DETAILS_REQUEST = 'app/DashboardPage/HOST_STRIPE_DETAILS_REQUEST';
export const HOST_STRIPE_DETAILS_SUCCESS = 'app/DashboardPage/HOST_STRIPE_DETAILS_SUCCESS';
export const HOST_STRIPE_DETAILS_ERROR = 'app/DashboardPage/HOST_STRIPE_DETAILS_ERROR';

export const HOST_LISTINGS_SUMMARY_REQUEST = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_REQUEST';
export const HOST_LISTINGS_SUMMARY_SUCCESS = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_SUCCESS';
export const HOST_LISTINGS_SUMMARY_ERROR = 'app/DashboardPage/HOST_LISTINGS_SUMMARY_ERROR';

// ================ Reducer ================ //

const initialState = {
  userDetailsInProgress: false,
  userDetailsError: null,
  userDetails: [],
  metadata: {},

  recentListingsInProgress: false,
  recentListingsError: null,
  recentListings: [],

  recentMessagesInProgress: false,
  recentMessagesError: null,
  recentMessages: [],

  recentTransactionsInProgress: false,
  recentTransactionsError: null,
  recentTransactions: [],

  transactionTransitionInProgress: false,
  transactionTransitionError: null,

  hostStripeDetailsInProgress: false,
  hostStripeDetailsError: null,
  hostStripeDetails: [],

  hostListingsSummaryInProgress: false,
  hostListingsSummaryError: null,
  hostListingsSummary: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailsInProgress: true,
        userDetailsError: null,
        userDetails: [],
        metadata: {},
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetailsInProgress: false,
        userDetails: payload.userData,
        metadata: payload.metadata,
      };
    case USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsInProgress: false,
        userDetailsError: payload,
        userDetails: [],
        metadata: {},
      };
    case HOST_STRIPE_DETAILS_REQUEST:
      return {
        ...state,
        hostStripeDetailsInProgress: true,
        hostStripeDetailsError: null,
        hostStripeDetails: [],
        metadata: {},
      };
    case HOST_STRIPE_DETAILS_SUCCESS:
      return {
        ...state,
        hostStripeDetailsInProgress: false,
        hostStripeDetailsError: null,
        hostStripeDetails: payload.hostStripeDetails,
        metadata: payload.metadata,
      };
    case HOST_STRIPE_DETAILS_ERROR:
      return {
        ...state,
        hostStripeDetailsInProgress: false,
        hostStripeDetailsError: payload,
        hostStripeDetails: [],
        metadata: {},
      };
    case HOST_LISTINGS_SUMMARY_REQUEST:
      return {
        ...state,
        hostListingsSummaryInProgress: true,
        hostListingsSummaryError: null,
        hostListingsSummary: [],
        metadata: {},
      };
    case HOST_LISTINGS_SUMMARY_SUCCESS:
      return {
        ...state,
        hostListingsSummaryInProgress: false,
        hostListingsSummaryError: null,
        hostListingsSummary: payload.hostListingsSummary,
        metadata: payload.metadata,
      };
    case HOST_LISTINGS_SUMMARY_ERROR:
      return {
        ...state,
        hostListingsSummaryInProgress: false,
        hostListingsSummaryError: payload,
        hostListingsSummary: [],
        metadata: {},
      };
    case RECENT_LISTINGS_REQUEST:
      return {
        ...state,
        recentListingsInProgress: true,
        recentListingsError: null,
        recentListings: [],
        metadata: {},
      };
    case RECENT_LISTINGS_SUCCESS:
      return {
        ...state,
        recentListingsInProgress: false,
        recentListingsError: null,
        recentListings: payload.listings,
        metadata: payload.metadata,
      };
    case RECENT_LISTINGS_ERROR:
      return {
        ...state,
        recentListingsInProgress: false,
        recentListingsError: payload,
        recentListings: [],
        metadata: {},
      };
    case RECENT_MESSAGES_REQUEST:
      return {
        ...state,
        recentMessagesInProgress: true,
        recentMessagesError: null,
        recentMessages: [],
        metadata: {},
      };
    case RECENT_MESSAGES_SUCCESS:
      return {
        ...state,
        recentMessagesInProgress: false,
        recentMessagesError: null,
        recentMessages: payload.messages,
        metadata: payload.metadata,
      };
    case RECENT_MESSAGES_ERROR:
      return {
        ...state,
        recentMessagesInProgress: false,
        recentMessagesError: payload,
        recentMessages: [],
        metadata: {},
      };
    case RECENT_TRANSACTIONS_REQUEST:
      return {
        ...state,
        recentTransactionsInProgress: true,
        recentTransactionsError: null,
        recentTransactions: [],
        metadata: {},
      };
    case RECENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        recentTransactionsInProgress: false,
        recentTransactionsError: null,
        recentTransactions: payload.transactions,
        metadata: payload.metadata,
      };
    case RECENT_TRANSACTIONS_ERROR:
      return {
        ...state,
        recentTransactionsInProgress: false,
        recentTransactionsError: payload,
        recentTransactions: [],
        metadata: {},
      };
    case TRANSACTION_TRANSITION_REQUEST:
      return {
        ...state,
        transactionTransitionInProgress: true,
        transactionTransitionError: null,
      };
    case TRANSACTION_TRANSITION_SUCCESS:
      return {
        ...state,
        transactionTransitionInProgress: false,
        transactionTransitionError: null,
      };
    case TRANSACTION_TRANSITION_ERROR:
      return {
        ...state,
        transactionTransitionInProgress: false,
        transactionTransitionError: payload,
      };

    default:
      return state;
  }
}
// ================ Selectors ================ //

export const userDetailsSelector = state => {
  const { userDetails, userDetailsInProgress, userDetailsError, metadata } = state.DashboardPage;
  return {
    userDetails,
    userDetailsInProgress,
    userDetailsError,
    metadata,
  };
};
export const hostStripeDetailsSelector = state => {
  const {
    hostStripeDetails,
    hostStripeDetailsInProgress,
    hostStripeDetailsError,
    metadata,
  } = state.DashboardPage;
  return {
    hostStripeDetails,
    hostStripeDetailsInProgress,
    hostStripeDetailsError,
    metadata,
  };
};
export const hostListingsSummarySelector = state => {
  const {
    hostListingsSummaryInProgress,
    hostListingsSummaryError,
    hostListingsSummary,
    metadata,
  } = state.DashboardPage;
  return {
    hostListingsSummaryInProgress,
    hostListingsSummaryError,
    hostListingsSummary,
    metadata,
  };
};
export const recentListingsSelector = state => {
  const {
    recentListings,
    recentListingsInProgress,
    recentListingsError,
    metadata,
  } = state.DashboardPage;
  return {
    metadata,
    recentListings,
    recentListingsInProgress,
    recentListingsError,
  };
};
export const recentMessagesSelector = state => {
  const {
    recentMessagesInProgress,
    recentMessagesError,
    recentMessages,
    metadata,
  } = state.DashboardPage;
  return {
    metadata,
    recentMessagesInProgress,
    recentMessagesError,
    recentMessages,
  };
};
export const recentTransactionsSelector = state => {
  const {
    recentTransactionsInProgress,
    recentTransactionsError,
    recentTransactions,
    metadata,
  } = state.DashboardPage;
  return {
    recentTransactionsInProgress,
    recentTransactionsError,
    recentTransactions,
    metadata,
  };
};
export const transactionTransitionSelector = state => {
  const { transactionTransitionInProgress, transactionTransitionError } = state.DashboardPage;
  return {
    transactionTransitionInProgress,
    transactionTransitionError,
  };
};

// ================ Action creators ================ //

export const userDetailsRequest = () => ({ type: USER_DETAILS_REQUEST });

export const userDetailsSuccess = (userData, metadata) => ({
  type: USER_DETAILS_SUCCESS,
  payload: { userData, metadata },
});

export const userDetailsError = e => ({
  type: USER_DETAILS_ERROR,
  error: true,
  payload: e,
});
export const hostStripeDetailsRequest = () => ({ type: HOST_STRIPE_DETAILS_REQUEST });

export const hostStripeDetailsSuccess = (hostStripeDetails, metadata) => ({
  type: HOST_STRIPE_DETAILS_SUCCESS,
  payload: { hostStripeDetails, metadata },
});

export const hostStripeDetailsError = e => ({
  type: HOST_STRIPE_DETAILS_ERROR,
  error: true,
  payload: e,
});
export const hostListingsSummaryRequest = () => ({ type: HOST_LISTINGS_SUMMARY_REQUEST });

export const hostListingsSummarySuccess = (hostListingsSummary, metadata) => ({
  type: HOST_LISTINGS_SUMMARY_SUCCESS,
  payload: { hostListingsSummary, metadata },
});

export const hostListingsSummaryError = e => ({
  type: HOST_LISTINGS_SUMMARY_ERROR,
  error: true,
  payload: e,
});
export const recentListingsRequest = () => ({ type: RECENT_LISTINGS_REQUEST });

export const recentListingsSuccess = (listings, metadata) => ({
  type: RECENT_LISTINGS_SUCCESS,
  payload: { listings, metadata },
});

export const recentListingsError = e => ({
  type: RECENT_LISTINGS_ERROR,
  error: true,
  payload: e,
});
export const recentMessagesRequest = () => ({ type: RECENT_MESSAGES_REQUEST });

export const recentMessagesSuccess = (messages, metadata) => ({
  type: RECENT_MESSAGES_SUCCESS,
  payload: { messages, metadata },
});

export const recentMessagesError = e => ({
  type: RECENT_MESSAGES_ERROR,
  error: true,
  payload: e,
});
export const recentTransactionsRequest = () => ({ type: RECENT_TRANSACTIONS_REQUEST });

export const recentTransactionsSuccess = (transactions, metadata) => ({
  type: RECENT_TRANSACTIONS_SUCCESS,
  payload: { transactions, metadata },
});

export const recentTransactionsError = e => ({
  type: RECENT_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});
export const transactionTransitionRequest = () => ({ type: TRANSACTION_TRANSITION_REQUEST });

export const transactionTransitionSuccess = () => ({
  type: TRANSACTION_TRANSITION_SUCCESS,
});

export const transactionTransitionError = e => ({
  type: TRANSACTION_TRANSITION_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchUserDetails = params => async (dispatch, getState, sdk) => {
  dispatch(userDetailsRequest());
  try {
    const response = await fetchUsersDetails(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const userData = response?.data?.userData || [];
      dispatch(userDetailsSuccess(userData, metadata));
    }
  } catch (error) {
    dispatch(userDetailsError(storableError(error)));
  }
};
export const fetchRecentListings = params => async (dispatch, getState, sdk) => {
  dispatch(recentListingsRequest());
  try {
    const response = await fetchListingDetails(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const listingsData = response?.data?.listingsData || [];
      dispatch(recentListingsSuccess(listingsData, metadata));
    }
  } catch (error) {
    dispatch(recentListingsError(storableError(error)));
  }
};
export const fetchRecentMessages = params => async (dispatch, getState, sdk) => {
  dispatch(recentMessagesRequest());
  try {
    const response = await fetchMessagesDetails(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const messagesData = response?.data?.messagesData || [];
      dispatch(recentMessagesSuccess(messagesData, metadata));
    }
  } catch (error) {
    dispatch(recentMessagesError(storableError(error)));
  }
};
export const fetchRecentTransactions = params => async (dispatch, getState, sdk) => {
  dispatch(recentTransactionsRequest());
  try {
    const response = await fetchTransactionsDetails(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const transactions = response?.data?.transactions || [];
      dispatch(recentTransactionsSuccess(transactions, metadata));
    }
  } catch (error) {
    dispatch(recentTransactionsError(storableError(error)));
  }
};
export const createTransactionTransition = params => async (dispatch, getState, sdk) => {
  dispatch(transactionTransitionRequest());
  try {
    const response = await transactionTransition(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      dispatch(transactionTransitionSuccess());
    }
  } catch (error) {
    dispatch(transactionTransitionError(storableError(error)));
  }
};
export const getHostsStripeDetails = params => async (dispatch, getState, sdk) => {
  dispatch(hostStripeDetailsRequest());
  try {
    const response = await fetchHostsStripeDetails(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const hostStripeDetails = response?.data?.hostStripeDetails || [];
      dispatch(hostStripeDetailsSuccess(hostStripeDetails, metadata));
    }
  } catch (error) {
    dispatch(hostStripeDetailsError(storableError(error)));
  }
};
export const getHostListingsSummary = params => async (dispatch, getState, sdk) => {
  dispatch(hostListingsSummaryRequest());
  try {
    const response = await fetchHostListingsSummary(params);
    if (response.statusCode === StatusCode.SUCCESS) {
      const metadata = response?.data?.meta || {};
      const hostListingsSummary = response?.data?.hostListingsSummary || [];
      dispatch(hostListingsSummarySuccess(hostListingsSummary, metadata));
    }
  } catch (error) {
    dispatch(hostListingsSummaryError(storableError(error)));
  }
};

export const loadData = params => (dispatch, getState, sdk) => {
  const tab = params?.tab;
  const tabActions = new Map([
    [DashboadPageNavOption.USER_ACTIONS, () => dispatch(fetchUserDetails(PAGE))],
    [DashboadPageNavOption.LISTING_ACTIVITY, () => dispatch(fetchRecentListings(PAGE))],
    [DashboadPageNavOption.INBOX_SUPERHOG, () => dispatch(fetchRecentMessages(PAGE))],
    [DashboadPageNavOption.TRANSACTIONS_DETAILS, () => dispatch(fetchRecentTransactions(PAGE))],
    [DashboadPageNavOption.HOST_STATUS_STRIPE, () => dispatch(getHostsStripeDetails(PAGE))],
    [DashboadPageNavOption.HOST_LISTINGS_SUMMARY, () => dispatch(getHostListingsSummary(PAGE))],
  ]);

  // Execute the appropriate action for the given tab
  const action = tabActions.get(tab);
  if (action) {
    action();
  }

  // Always fetch the current user
  return dispatch(fetchCurrentUser());
};
